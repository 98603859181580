import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import Layout from '../layouts/Layout';

const NotFoundPage = ({ location, site }) => (
  <Layout location={location} siteMetadata={site.siteMetadata}>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export const NotFound = ({ location, site }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            tenantId
            googleApiKey
          }
        }
      }
    `}
    render={(props) => <NotFoundPage {...props} location={location} />}
  />
);

export default NotFound;
